#about {
  background-color: rgb(5, 5, 30);
  border-bottom: solid 0.1rem #1aecff8f;
    margin-top: 35rem;
    padding: 3.125rem 3.125rem;
  }

  .about-contenair {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;
  }
  
  h1{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .textline{
    text-align: center;
    border-bottom: 5px solid #1aecff;
    display: inline-block; 
    margin-bottom: 1rem;
    height: 1px;
    width: 10%;
  }
  .about-section p{
    margin-top: 10px;
    max-width: 40ch;
    padding-bottom: 2rem;
  }
.aScan {
  color:  rgb(167, 167, 202);
}
.aScan:hover{
  color:rgba(210,146,255);
}
  .image-container img {
    height: 100vh;
    width: auto;
    border-radius: 4px;
    z-index: 2;
}

  @media (max-width:900px) {
    #about {
      margin-top: 25rem;
      padding: 0rem;

    }
    .about-contenair {
      display: flex;
      flex-direction: column-reverse;
      padding: 0;
      margin: 0;
    }
    .aScan{
      color: rgb(212, 203, 203);
    }
    .image-container img{
      width: 100%;
      height: auto;
    }
  }