#skill{
    background-color: rgb(5, 5, 30);
    padding: 3rem 0 1rem 0;
    border-bottom: solid 0.1rem #1aecff8f;


}
.skill-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: 1rem;
    justify-items: center;
    padding: 1rem;
    transition: transform 0.3s ease-in-out;

}
  
  

  .skill-item{
    padding: 1rem 0 1rem 0;
    font-size: 10rem;
    text-align: center;
    width: 90%;
    transition: transform 0.3s ease-in-out;
}
  .skill-item:hover{
    box-shadow: 0px 0.5px 0.5px 0.5px rgb(32, 21, 93);
    transform: scale(1.1);

  }
  .css3 { color: #1572B6; }
  .html5 { color: #E34F26; }
  .JavaScript { color: rgb(240, 219, 79); }
  .react { color: #61DAFB; }
  .nodejs { color: #339933; }
  .express { color: #008080; }
  .mongodb { color: #47A248; }
  