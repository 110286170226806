.home {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 6.25rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1; 
}

.home h1 {
    /* background: linear-gradient(90deg, #1aecff, #ff1a62);
    -webkit-background-clip: text; */
    /* color: transparent; */
    font-size: 8rem;
}

/* .home h1::after {
    content: attr(data-text); 
    display: block;
    background: linear-gradient(90deg, #1aecff, #ff1a62);
    -webkit-background-clip: text;
    color: transparent;
    visibility: hidden;
  } */

.home p {
    padding: 1rem;
}


button:hover {
    transform: translate(5px);}


    @media  (max-width: 900px) {
        .home {
            width: 100%;
            padding-top: 5rem;


        }
        .home h1 {

            font-size: 3rem;
            margin-bottom:1rem ;
        }
    }