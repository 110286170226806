footer {
  background-color: black;
}
.footer-Section {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 50PX ;
}
.footer-Section h3 {
  margin-bottom: 10px;
}
.footer-Section p {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 1.6;
  font-weight: 400;
  color: white }
.social-media-container {
    display: flex;
    align-items: center;
    justify-content:center ;
  }
  
  /* .social-media-container a {
    margin-right: 10px;
  } */
  
  .social-media-container a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    color: black;
    border-radius: 50%;
    padding: 0.3rem;
    margin-right: 10px;
    transition: transform 0.3s ease-in-out;

  }
  
  .social-media-container a:hover{
    background-color:#1aecff ;
    transform: scale(1.5);
  }
  .Copyright {
    display: flex;
    justify-content: center;
   padding: 50px;
  }
  .Copyright p {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 1.6;
    font-weight: 400;
    color: white 
  }
  .Copyright a {
    margin: 10px;
    color: white;
  }
.the-line {
    border:solid 1px rgb(117, 117, 117);
margin: 50PX;}
@media (max-width:900px) {
  .footer-Section {
    display: flex;
    flex-direction: column;
  }
  .social-media-main-container{
    margin-top: 2rem;
  }
}