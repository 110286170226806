
*{
    margin: 0;
    padding: 0;
}
.navbar {
    width: 100%;
    background-color: rgba(7, 7, 46, 0.955);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* .logo-container {
    display: flex;
    align-items: center;
}

.logo-container img {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 1rem;
} */

nav h1 {
    color:  #1aecff;
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    padding-left: 1rem;
    /* background: linear-gradient(90deg, #1aecff, #f76593);
    -webkit-background-clip: text;
    color: transparent; */
}
/* nav h1::after {
    content: attr(data-text); 
    display: block;
    background: linear-gradient(90deg, #1aecff, #ff1a62);
    -webkit-background-clip: text;
    color: transparent;
    visibility: hidden;
  } */

.nav-menu {
    display: flex;
    list-style: none;
    margin:0;
    padding: 0;
}

.nav-menu li {
    margin-right: 1.25rem;
}

.nav-menu li a {
    text-decoration: none;
    color: rgb(223, 222, 222);
    font-size: 1.125rem;
    font-weight: bold;
}

.nav-menu li a:hover {
    color:#1aecff ;

}
.hamburger {
    display: none;
    padding: 1rem;
    font-size: 2rem;
 }
 
 @media screen and (max-width: 940px) {
     .hamburger {
        display: flex;
        z-index: 1;
    }
    .navbar {
        justify-content: space-between;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:100%;
        align-items:flex-end;
        justify-content: flex-start;
        margin-top: 3.9rem;
        background-color: rgb(5, 5, 30);
        transition: 0.5s ease-in;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        margin-right: 1rem;
        font-size: 2rem;
    }

    /* .navbar button {
        font-size: 1.8rem;
    } */
}