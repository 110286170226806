body {
  background-color: rgb(5, 5, 30);
  color:rgb(207, 208, 250);
  border-bottom: solid 0.1rem #1aecff;

}

p{
    font-family: "Raleway";
    color:  rgb(167, 167, 202);
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
}

.textline{
  color:#1aecff ;
  height: 40vh;
  width: 100%;
}

.theLine{
  width: 100%;
  height: 0.1rem;
  margin-top: 1rem;
  background-color:#1aecff ;
}

@media (max-width:900px) {
  h1 {
    font-size: 3.13rem !important;
  }
 p {
    font-family: "Space Grotesk Variable", sans-serif;
    letter-spacing: -.02em;
    color: rgb(212, 203, 203) !important;
  }

}

button {
  z-index: 1;
  border-radius: 4px;
  border: solid rgb(82, 163, 163);
  position: relative;
  font-size: inherit;
  font-family: inherit;
  color: rgb(212, 194, 194);
  padding: 0.5em 1em;
  outline: none;
  background-color: rgba(82, 163, 163, 0);
  overflow: hidden;
  transition: color 0.4s ease-in-out;
}

button::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 100%;
  right: 100%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: rgb(82, 163, 163);
  transform-origin: center;
  transform: translate3d(50%, -50%, 0) scale3d(0, 0, 0);
  transition: transform 0.45s ease-in-out;
}

button:hover {
  cursor: pointer;
  color: #161616;
}

button:hover::before {
  transform: translate3d(50%, -50%, 0) scale3d(15, 15, 15);
}


button:hover {
  background-color: rgb(82, 163, 163);
  transition: 1s;
  cursor:pointer;}

  