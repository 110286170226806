* {
  margin: 0;
  padding: 0;
}


.form-container {
  background-color: rgb(5, 5, 30);
  padding-top: 0.625rem; 
  padding-bottom: 3.125rem; 
}


.form-container div {
  text-align: center;
  margin: 3.125rem;
  margin-top: 3.125rem; 
}


.form-container div p{
  color:#1aecff ;
  font-family: "Raleway";
  font-size: 1.3rem;


}
.contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 30rem; 
  margin: 0 auto;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder{
  color:  rgb(167, 167, 202);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.9375rem; 
  margin-bottom: -0.5rem; 
  font-size: 1.2rem;
  resize:both;
  background-color: rgba(9, 9, 9, 0.502);
  box-shadow: 0px 0.5px 0.5px 0.5px rgb(32, 21, 93);
  border: none;
  color: rgb(213, 212, 212);


}

.contact-form button {
  align-self: flex-end;
}

@media (max-width: 900px) { /* 480px converted to rem */
  .contact-form {
  margin: 0 auto;
  }
  .contact-form input,
.contact-form textarea{
  width: 90%;

}
}
