.projects-container {
    background-color: rgb(5, 5, 30);
    padding: 50px 50px;
    border-bottom: solid 0.1rem #1aecff;
}

.project {
    text-align: center;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.project-item {
    background-color: rgba(8, 8, 45, 0.709);
    border-radius: 4px;
    padding: 2rem;
    box-shadow: 0px 0.5px 0.5px 0.5px rgb(32, 21, 93);
    transition: transform 0.3s ease-in-out;

}
.project-item:hover{
    transform: scale(1.1);
}

.project-item img {
    width: 100%;
    border-radius: 4px;
}

@media (max-width: 900px) {
    .project-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .project-item{
        padding: 1rem;
    }
    .projects-container{
        padding: 1rem 1rem;

    }
}
